<template>
  <base-page-layout title="Usuário">
    <v-row>
      <v-col cols="12">
        <mf-card-container title="Cadastro de usuário" :description="'Cadastro de usuários internos e externos.'">
          <v-row justify="center">
            <v-col cols="8">
              <v-container fluid class="pa-0" grid-list-xl>
                <v-layout row wrap>
                  <v-flex v-for="(type, index) in types" :key="`${index}-${type.text}`" xs6 md6>
                    <type-card class="mr-2 ml-2" :text="type.text" :sales-type="type.value" :selected.sync="selectedType" />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-col>
          </v-row>
          <v-form ref="form" v-model="valid" class="auth-form" lazy-validation>
            <form-internal v-if="selectedType === 'internal'" :user.sync="user"></form-internal>
            <form-external v-if="selectedType === 'external'" :user.sync="user"></form-external>
          </v-form>
        </mf-card-container>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center">
      <v-col cols="6">
        <v-row>
          <v-col cols="auto">
            <mf-button color="error" text :label="'Cancelar'" @click="$router.push(`/usuarios`)"></mf-button>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row justify="end">
          <v-col cols="auto">
            <mf-button :disabled="selectedType === '' || disableButton" label="Salvar" @click="saveUser"></mf-button>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </base-page-layout>
</template>

<script>
import { MUTATION_SAVE_INTERNAL_USER } from '@/modules/users/graphql'
import { PAGES } from '@/constants/platform'
export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue'),
    TypeCard: () => import('@/components/atomic-components/atoms/TypeCard.vue'),
    FormInternal: () => import('@/modules/users/components/forms/FormInternal.vue'),
    FormExternal: () => import('@/modules/users/components/forms/FormExternal.vue')
  },
  data: () => ({
    valid: false,
    selectedType: '',
    disableButton: false,
    forcePush: false,
    user: {
      name: '',
      login: '',
      password: '',
      admin: false,
      mf: false,
      multiUser: false,
      accounts: null,
      passwordConfirm: '',
      pages: PAGES,
      listable: true,
      id_account: null,
      franchisesId: ''
    },
    noEmptyRule: v => !!v || 'Campo obrigatório',
    passwordRule: v => (v || '').length >= 8 || 'Senha deve ter no mínimo 8 caracteres',
    loginRule: v => /.+@.+\..+/.test(v) || 'Login precisa ser um email válido',
    types: [
      {
        text: 'Usuário Interno',
        value: 'internal'
      },
      {
        text: ' Usuário Externo',
        value: 'external'
      }
    ]
  }),
  computed: {
    variable() {
      let user = { ...this.user }
      if (this.selectedType === 'external') {
        user.id_account = user.accounts[0]
        if (user.accounts.length === 1) delete user.accounts
      } else {
        if (user.mf) {
          delete user.pages
        }
        delete user.id_account
        delete user.accounts
      }
      return user
    },
    accounts() {
      let result = null
      if (this.variable.accounts) {
        result = this.variable.franchisesId !== '' ? this.variable.accounts.concat(this.variable.franchisesId) : this.variable.accounts
      }
      return result
    }
  },
  watch: {
    selectedType() {
      this.user = {
        name: '',
        login: '',
        password: '',
        admin: false,
        mf: false,
        multiUser: false,
        accounts: null,
        passwordConfirm: '',
        pages: PAGES,
        listable: true,
        id_account: null,
        franchisesId: ''
      }
    }
  },
  methods: {
    async saveUser() {
      this.disableButton = true
      if (!this.$refs.form.validate())
        return this.$alert({
          alert_message: 'Falha ao validar campos',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      try {
        await this.$apollo
          .mutate({
            mutation: MUTATION_SAVE_INTERNAL_USER,
            variables: {
              name: this.variable.name,
              login: this.variable.login,
              password: this.variable.password,
              mf: this.variable.mf,
              active: true,
              admin: false,
              listable: this.variable.listable,
              pages: this.variable.pages,
              id_account: this.variable.id_account,
              accounts: this.accounts
            }
          })
          .then(() => {
            setTimeout(() => {
              this.forcePush = true
              this.$router.push(`/usuarios`)
            }, 100)
            this.$alert({
              alert_message: `Usuário cadastrado com sucesso`,
              alert_title: 'Sucesso!',
              alert_color: 'success',
              alert_icon: 'mdi-check-circle'
            })
          })
          .catch(err => {
            this.$alert({
              alert_message: `${err.message.split('GraphQL error:')[1]}`,
              alert_title: 'Erro!',
              alert_color: 'error',
              alert_icon: 'mdi-close-circle'
            })
            console.log(err.message)
          })
      } catch (err) {
        this.disableButton = false

        console.log(err)
        this.$alert({
          alert_message: 'Falha ao salvar usuário',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.forcePush) {
      this.$confirm({
        title: 'Tem certeza?',
        message: 'O usuário ainda não foi salvo e todas as suas alterações recentes serão perdidas ao sair dessa página.',
        confirmText: 'FICAR E CONTINUAR CRIAÇÃO',
        cancelText: 'SAIR DA TELA'
      })
        .then(() => {
          next(false)
        })
        .catch(() => {
          next()
        })
    } else {
      next()
    }
  }
}
</script>
